import * as React from "react";
import "styles/global.css";
import Helmet from "react-helmet";
import { SizeMe as Size } from "react-sizeme";

export default function HomePage() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Rachel Risko</title>
      </Helmet>

      <Size monitorHeight>
        {({ size }) => (
          <div>
            <svg
              viewBox={`0 0 ${size.width} ${size.height}`}
              width={size.width}
              height={size.height}
              style={{
                position: "absolute",
                top: 0,
                pointerEvents: "none",
              }}
            >
              {Array.apply(null, new Array(16)).map((_, index) => (
                <line
                  key={index}
                  x1={(size.width / 12) * 6 + index * 32}
                  y1={0}
                  x2={(size.width / 4) * 3 + index * 32 + 240}
                  y2={(size.height / 4) * 3}
                  style={{ stroke: "#DDCDBA" }}
                />
              ))}
              {Array.apply(null, new Array(16)).map((_, index) => (
                <line
                  key={index}
                  x1={(size.width / 4) * 3 + index * 32 + 240}
                  y1={(size.height / 4) * 3}
                  x2={96 + index * 64}
                  y2={size.height}
                  style={{ stroke: "#DDCDBA" }}
                />
              ))}
            </svg>

            {/* Header ----> */}
            <header className="bg-black">
              <div className="container mx-auto px-8 py-8 flex items-center">
                <div className="font-bold tracking-tighter text-2xl text-white">
                  R<sup>2</sup>
                </div>
                <div className="flex-1" />
                {/* <div className="tracking-wide text-sm text-white mr-4">
                  Resume
                </div> */}
                <a
                  className="tracking-wide text-sm text-white"
                  href="https://www.linkedin.com/in/rachel-risko/"
                >
                  LinkedIn
                </a>
              </div>
            </header>
            {/* <---- Header */}

            {/* Hero ----> */}
            <section className="bg-black">
              <div className="container mx-auto px-8 pt-24 pb-96 relative">
                <h1
                  className="
                    text-7xl
                    md:text-9xl
                    lg:text-11xl
                    lg:-mb-32
                    lg:-ml-2
                    xl:-mb-32
                    xl:-ml-5
                    xl:text-12xl
                    leading-none
                    whitespace-nowrap
                    font-bold
                    tracking-tighter"
                  style={{ color: "#FFF" }}
                >
                  <div className="xl:-mb-16">Rachel</div>
                  <div>Risko</div>
                </h1>
                <div className="grid md:grid-cols-12 relative">
                  <div className="md:col-span-8 lg:col-start-6 lg:col-span-6 xl:col-start-6 xl:col-span-6">
                    <div className="lg:bg-black lg:bg-opacity-80 lg:p-12 mt-12 space-y-4">
                      <h2 className="text-xl md:text-3xl font-medium tracking-tight text-white">
                        Product Manager & Fitness Enthusiast
                      </h2>
                      <p className="xl:text-lg text-white">
                        Rachel is a Brooklyn-based product manager whose
                        background in business and engineering and love of
                        fitness led her to Equinox, where she currently leads
                        work on the member-facing application. With experience
                        in strategy, design, and development across both web and
                        app, Rachel is passionate about products that inspire,
                        motivate, and impact the world positively.
                      </p>
                      <p className="xl:text-lg text-white">
                        Growing up, Rachel played sports competitively and
                        learned important lessons in discipline, teamwork, and
                        grit. She uses these same lessons when approaching
                        problems and working with diverse teams. When Rachel
                        isn’t working (or working out), she’s exploring the
                        newest vegan restaurants, attending concerts and
                        festivals, or spending time with her two dogs, Kona and
                        Bean.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <---- Hero */}
          </div>
        )}
      </Size>

      {/* Footer ----> */}
      <footer className="bg-white">
        <div className="container mx-auto px-8 py-8 flex items-center">
          <div className="font-bold tracking-tighter text-2xl">
            R<sup>2</sup>
          </div>
          <div className="flex-1" />
          <div className="text-gray-500">Killing it.</div>
        </div>
      </footer>
      {/* <---- Footer */}

      {/* Hero ----> */}
      {/* <section className="bg-black">
        <div className="container mx-auto pt-24 pb-96">
          <h1 className="-mb-32 ml-4">
            <div
              className="text-12xl leading-none whitespace-nowrap italic tracking-tighter text-white -mb-8"
              style={{ fontFamily: "Playfair Display" }}
            >
              Rachel
            </div>
            <div
              className="text-12xl leading-none whitespace-nowrap italic tracking-tighter text-white"
              style={{ fontFamily: "Playfair Display" }}
            >
              Risko
            </div>
          </h1>
          <div className="grid md:grid-cols-12 relative">
            <div className="col-start-6 col-span-6">
              <div className="border border-gray-800 bg-black bg-opacity-80 p-12">
                <h2 className="text-3xl font-bold tracking-tight mb-8 text-white">
                  Product Manager & Athlete
                </h2>
                <p className="text-lg text-white">{summary}</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <---- Hero */}

      {/* Statistics ----> */}
      {/* <section className="container mx-auto py-24">
        <h1
          className="text-12xl italic tracking-tighter -mb-32 ml-4 text-gray-200 whitespace-nowrap"
          style={{ fontFamily: "Playfair Display" }}
        >
          Statistics
        </h1>
        <div className="grid md:grid-cols-12 gap-8">
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">1000</div>
              <div className="font-bold mt-12">Miles Run</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">840</div>
              <div className="font-bold mt-12">Miles cycled</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">211</div>
              <div className="font-bold mt-12">Cookies baked</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">631</div>
              <div className="font-bold mt-12">Workouts</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">20</div>
              <div className="font-bold mt-12">Yoga Workouts</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">165</div>
              <div className="font-bold mt-12">Strength workouts</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">437</div>
              <div className="font-bold mt-12">All-time rides</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">4758</div>
              <div className="font-bold mt-12">All-time miles cycled</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">155h</div>
              <div className="font-bold mt-12">Ride time this year</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">2877</div>
              <div className="font-bold mt-12">Miles cycled this year</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg bg-white p-8">
              <div className="text-9xl">1286</div>
              <div className="font-bold mt-12">Miles climbed this year</div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <---- Statistics */}

      {/* <section className="container mx-auto py-48">
        <h1 className="text-9xl mb-8">Rachel Risko</h1>
        <h2 className="text-4xl">Product Manager</h2>
      </section>
      <section className="container mx-auto">
        <h1 className="text-9xl">Statistics</h1>
      </section>
      <section className="container mx-auto py-24">
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-4">
            <div className="border-b border-black font-bold">Miles Run</div>
            <div className="text-9xl">1000</div>
          </div>
          <div className="col-span-4">
            <div className="text-9xl">840</div>
            <div className="font-bold border-b border-black pb-2 mt-24">
              Miles Cycled
            </div>
          </div>
          <div className="col-span-4">
            <div className="border-b border-black font-bold">Cookies Baked</div>
            <div className="text-9xl">200</div>
          </div>
          <div className="col-span-4">
            <div className="border border-black p-8">
              <div className="text-9xl">631</div>
              <div className="font-bold mt-12">Workouts</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="border border-black p-8">
              <div className="text-9xl">20</div>
              <div className="font-bold mt-12">Yoga Workouts</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="border border-black p-8">
              <div className="text-9xl">165</div>
              <div className="font-bold mt-12">Strength workouts</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg p-8">
              <div className="text-9xl">437</div>
              <div className="font-bold mt-12">All-time rides</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg p-8">
              <div className="text-9xl">4758</div>
              <div className="font-bold mt-12">All-time miles cycled</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg p-8">
              <div className="text-9xl">155h</div>
              <div className="font-bold mt-12">Ride time this year</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg p-8">
              <div className="text-9xl">2877</div>
              <div className="font-bold mt-12">Miles cycled this year</div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="shadow-lg p-8">
              <div className="text-9xl">1286</div>
              <div className="font-bold mt-12">Miles climbed this year</div>
            </div>
          </div>
        </div>
      </section>

      <header className="bg-black">
        <div className="container mx-auto py-8 flex items-center">
          <div className="font-bold tracking-tighter text-2xl text-white">
            R<sup>2</sup>
          </div>
          <div className="flex-1" />
          <div className="tracking-wide text-sm text-white mr-4">Resume</div>
          <div className="tracking-wide text-sm text-white">LinkedIn</div>
        </div>
      </header>

      <section className="bg-black">
        <div className="container mx-auto pt-24 pb-96">
          <h1 className="-mb-32 -ml-5">
            <div className="text-12xl leading-none whitespace-nowrap font-bold tracking-tighter text-white">
              Rachel
            </div>
            <div className="text-12xl leading-none whitespace-nowrap font-bold tracking-tighter text-white">
              Risko
            </div>
          </h1>
          <div className="grid md:grid-cols-12 relative">
            <div className="col-start-6 col-span-6">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-12">
                <h2 className="text-4xl font-bold tracking-tight mb-8 text-white">
                  Product Manager
                </h2>
                <p className="text-lg text-white">{summary}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black">
        <div className="container mx-auto">
          <h1 className="text-12xl font-bold tracking-tighter -mb-64 -ml-4 text-gray-200 whitespace-nowrap text-white">
            Statistics
          </h1>
        </div>
      </section>
      <section className="bg-black">
        <div className="container mx-auto py-24 relative">
          <div className="grid md:grid-cols-12 gap-8">
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">1000</div>
                <div className="font-bold mt-24 text-white">Miles Run</div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">840</div>
                <div className="font-bold mt-24 text-white">Miles cycled</div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">211</div>
                <div className="font-bold mt-24 text-white">Cookies baked</div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">631</div>
                <div className="font-bold mt-24 text-white">Workouts</div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">20</div>
                <div className="font-bold mt-24 text-white">Yoga Workouts</div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">165</div>
                <div className="font-bold mt-24 text-white">
                  Strength workouts
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">437</div>
                <div className="font-bold mt-24 text-white">All-time rides</div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">4758</div>
                <div className="font-bold mt-24 text-white">
                  All-time miles cycled
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">155h</div>
                <div className="font-bold mt-24 text-white">
                  Ride time this year
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">2877</div>
                <div className="font-bold mt-24 text-white">
                  Miles cycled this year
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="border border-gray-500 bg-black bg-opacity-80 p-8">
                <div className="text-9xl text-white">1286</div>
                <div className="font-bold mt-24 text-white">
                  Miles climbed this year
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="container mx-auto py-8 flex items-center">
        <div className="font-bold tracking-tighter text-2xl">
          R<sup>2</sup>
        </div>
        <div className="flex-1" />
        <div>CV</div>
      </header>

      <section className="container mx-auto py-24">
        <h1 className="-mb-32 -ml-5">
          <div className="text-12xl leading-none whitespace-nowrap font-bold tracking-tighter">
            Rachel
          </div>
          <div className="text-12xl leading-none whitespace-nowrap font-bold tracking-tighter">
            Risko
          </div>
        </h1>
        <div className="grid md:grid-cols-12 relative">
          <div className="col-start-6 col-span-6">
            <div className="shadow-lg bg-white p-12">
              <h2 className="text-4xl font-bold tracking-tight mb-8">
                Product Manager
              </h2>
              <p className="text-lg">{summary}</p>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="container mx-auto">
          <div>1000</div>
          <div>Miles Run</div>
          <div>840</div>
          <div>Miles Cycled</div>
          <div>200</div>
          <div>Cookies Baked</div>
          <div>631</div>
          <div>Workouts</div>
          <div>20</div>
          <div>Yoga workouts</div>
          <div>165</div>
          <div>Strength workouts</div>
          <div>437</div>
          <div>All-time Rides</div>
          <div>437</div>
          <div>All-time Rides</div>
          <div>4758</div>
          <div>All-time miles cycled</div>
          <div>286</div>
          <div>Rides this year</div>
          <div>155h 48m</div>
          <div>Ride time this year</div>
          <div>2877</div>
          <div>Miles cycled this year</div>
          <div>1286</div>
          <div>Miles climbed this year</div>
        </section> */}
      {/* <footer className="bg-black">
        <div className="container mx-auto py-8 flex items-center">
          <div className="font-bold tracking-tighter text-2xl text-white">
            R<sup>2</sup>
          </div>
          <div className="flex-1" />
          <div className="text-gray-500">Killing it.</div>
        </div>
      </footer> */}
    </div>
  );
}
